import React from 'react'
import Layout from 'components/Layout/Selection'
import SlotListing from './Listing'

const SlotSelection = (props) => (
  <Layout title="Home" {...props}>
    <SlotListing {...props} />
  </Layout>
)

export default SlotSelection
