import React, { useState, useRef } from 'react'
import useApi from 'hooks/useApi'
import { getAllSlots } from 'actions/slot'
import { customerLogin } from 'actions/login'
import { getCustomer } from 'actions/customer'
import { getSettings } from 'actions/setting'
import { GridContainer, GridItem } from 'components/Grid'
import SlotDateChooser from './SlotDateChooser'
import SlotTimetables from './SlotTimetables'
import useStyles from './styles'
import Recaptcha from 'components/Recaptcha'
import Booking from './Booking'

const SlotListingIndex = ({ location }) => {
  const classes = useStyles()

  const bookingBoxRef = useRef(null)
  const [bookingData, setBookingData] = useState()
  const [slotTimetable, setSlotTimetable] = useState()
  const query = new URLSearchParams(location.search)
  const token = query.get('token')
  const editable = query.get('editable') ?? '1'

  const [slots, settings, customer] = useApi(() =>
    Promise.all([
      getAllSlots({ where: [['isPrivate', '==', false]], order: ['name', 'asc'] }),
      getSettings(),
      token
        ? customerLogin(token).then(({ user }) =>
            getCustomer(user.uid).then(async (customer) => ({
              customer,
              user,
            }))
          )
        : null,
    ])
  )

  const onSlotDateChosen = async (data) => setBookingData(data)
  const onSelectTimetable = async (time) => {
    setSlotTimetable(time)
    bookingBoxRef.current.openDrawer()
  }

  // const afterBooked = async (booking, navigatePaymentPage) => navigatePaymentPage(booking, query.toString())
  // if (booking.fee) {
  //   const res = await payBooking(booking.id, query.toString())
  //   window.location = res.data?.url
  // } else {
  //   redirectSuccessPage(booking, query.toString())
  // }
  // }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.title}>Welcome!</h2>
          <div className={classes.message}>{settings?.welcome}</div>
        </GridItem>
      </GridContainer>
      <SlotDateChooser slots={slots} bookingData={bookingData} onSave={onSlotDateChosen} />
      {bookingData && (
        <SlotTimetables bookingData={bookingData} settings={settings} onSelectTimetable={onSelectTimetable} />
      )}
      <Recaptcha>
        <Booking
          slotTimetable={slotTimetable}
          fee={bookingData?.fee}
          slot={bookingData?.slot}
          settings={settings}
          ref={bookingBoxRef}
          customer={customer}
          editable={editable === '1'}
          location={location}
        />
      </Recaptcha>
    </>
  )
}

export default SlotListingIndex
