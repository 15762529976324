import React, { useState, forwardRef, useEffect } from 'react'
import Form from './Form'
import Row from '../Row'
import {
  PeopleTwoTone as PeopleTwoToneIcon,
  ArrowForwardIosTwoTone as ArrowForwardIosTwoToneIcon,
} from '@material-ui/icons'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { SLOT_PUBLIC_LABEL } from 'config/app'
import validationSchema from './validations'
import { yupResolver } from '@hookform/resolvers/yup'
import defaultValues from './defaults'
import { useForm } from 'react-hook-form'

export default forwardRef(({ bookingData, onSave, slots, ...props }, ref) => {
  const [open, setOpen] = useState(false)
  const [display, setDisplay] = useState({ title: `Choose a ${SLOT_PUBLIC_LABEL}`, subheader: 'Choose a date' })
  const hookForm = useForm({
    defaultValues: bookingData ?? defaultValues,
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (bookingData && slots) {
      setDisplay({ title: bookingData.slot.name })
      hookForm.reset(bookingData)
    }
  }, [bookingData, slots])

  const onOpenDrawer = () => {
    setOpen(true)
  }

  const onSaveForm = (data) => onSave(data).then(() => onClose())

  const onClose = () => {
    setTimeout(() => setOpen(false), 100)
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Row
          avatarIcon={<PeopleTwoToneIcon />}
          title={display.title}
          actionIcon={<ArrowForwardIosTwoToneIcon />}
          onClick={onOpenDrawer}
        />
        <Form open={open} onSave={onSaveForm} onClose={onClose} hookForm={hookForm} slots={slots} {...props} />
      </GridItem>
    </GridContainer>
  )
})
