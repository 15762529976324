import React from 'react'
import { graphql } from 'gatsby'
import SlotSelection from 'components/Public/Slot/selection'

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const IndexPage = (props) => <SlotSelection {...props} />

export default IndexPage
